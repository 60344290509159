.title {
  color: var(--1-W1, #fff);
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.027px;
  text-transform: capitalize;
  padding: 24px;
}

.header {
  border-bottom: 1px solid #393f45;
}
