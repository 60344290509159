@import '../../styles/vars.scss';

.card {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow;

  &:hover,
  &:focus {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.cardImage {
  position: relative;
  width: 320px;

  @media screen and (min-width: 1200px) {
    width: 190.4px;
    height: 240px;
  }
}

.cardImage img {
  border-radius: 12px;
}

.badge {
  z-index: 3;
  position: absolute;
  top: 8px;
  right: 16px;
}

.desc {
  color: var(--1-W1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;

  max-width: 190.4px;
  overflow: hidden;
}

.author {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 8px;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
  color: var(--1-W1);
}

.descList {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  flex-direction: column;
}

.rating {
  color: var(--1-W1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
  margin-top: 8px;
}

.cardImage {
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  img {
    width: 100%;
    // height: auto;
    border-radius: 12px;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2323230f;
    z-index: 2;
  }

  .mainImage {
    width: 100%;
    height: auto;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    transition: filter 0.3s ease;

    &.loading {
      filter: blur(8px);
    }
  }
}
