.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: 1px solid #555;

  @media screen and (min-width: 480px) {
    width: 480px;
    // padding-left: 15px;
    // padding-right: 15px;
  }

  @media screen and (min-width: 720px) {
    width: 80%;
  }

  @media screen and (min-width: 1280px) {
    width: 1112px;
  }
}

.ProductsBlock {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: 1px solid #555;

  @media screen and (min-width: 480px) {
    width: 480px;
    // padding-left: 15px;
    // padding-right: 15px;
  }

  @media screen and (min-width: 720px) {
    width: 80%;
  }

  @media screen and (min-width: 1280px) {
    width: 1112px;
    // height: 897px;
  }
}

.Product {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: 1px solid #555;

  @media screen and (min-width: 480px) {
    width: 480px;
    // padding-left: 15px;
    // padding-right: 15px;
  }

  @media screen and (min-width: 720px) {
    width: 80%;
  }

  @media screen and (min-width: 1280px) {
    width: 1112px;
    min-height: 887px;
  }
}
