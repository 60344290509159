.holder {
  display: flex;
  height: 32px;
  padding: 0px 10px 0px 12px;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.027px;
  text-transform: capitalize;
}
