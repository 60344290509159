.section {
  padding-top: 14.5px;
  padding-bottom: 15px;
}

.products {
  composes: section;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}
