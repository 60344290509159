.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.dropdownButton {
  display: flex;
  height: 36px;
  padding-right: 8px;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  // gap: 49px;
  gap: 29px;

  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.021px;
  text-transform: capitalize;
  cursor: pointer;
  background: transparent;
  border-radius: 6px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);

  &:focus {
    outline: none;
  }

  &::after {
    content: url('../../../public/img/down.svg');
    display: inline-block;
    width: 24px;
    height: 24px;

    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  &.active::after {
    content: url('../../../public/img/up.svg');
  }
}

.dropdownList {
  position: absolute;
  z-index: 4;
  margin: 0;
  overflow: hidden;
  display: flex;
  width: 132px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #1f1f1f;
  top: 125%;
  left: 43%;
}

.dropdownItem {
  cursor: pointer;
  list-style-type: none;
  transition: background-color 0.2s;

  display: flex;
  height: 32px;
  padding-left: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: transparent;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.021px;
  text-transform: capitalize;
}

.selected {
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.021px;
  text-transform: capitalize;
}

.dropdownSort {
  display: flex;
  width: 100px;
  align-items: center;
  gap: 8px;

  color: #fff;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.021px;
  text-transform: capitalize;

  &::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../../../public/img/updown.svg');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}
